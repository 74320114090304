<div class="container-header">
  <div class="icon-container">
    <span nz-icon nzType="arrow-left" nzTheme="outline"
      (click)="back()"></span>
  </div>
  <div class="content-container">
    <h1>{{receivedObject?.vers?.titolo.ITA}} </h1>
    <h5>Scadenza : {{ receivedObject?.scadenza?.seconds * 1000 | date:
      'dd/MM/yyyy' }}</h5>

  </div>
</div>

<div class="functionality-amajor">
  <div class="statistic-send">
    <nz-row>
      <nz-col>
        <nz-card>
          <nz-statistic
            [nzValue]="nuberOfLicenceUsed + '/' + receivedObject.totali "
            [nzTitle]="'Utilizzate'"
            [nzPrefix]="prefixTplOne"
            [nzValueStyle]="{ color: 'blue' }"></nz-statistic>
          <ng-template #prefixTplOne><span nz-icon></span></ng-template>
        </nz-card>

        <nz-card>
          <nz-statistic
            [nzValue]="numberOfInProgress"
            [nzTitle]="listStateOfQuestionaire[2]"
            [nzPrefix]="prefixTplTwo"
            [nzValueStyle]="{ color: 'orange' }"></nz-statistic>
          <ng-template #prefixTplTwo><span nz-icon></span></ng-template>
        </nz-card>
      </nz-col>
      <nz-col>
        <nz-card>
          <nz-statistic
            [nzValue]="numberOfComplete"
            [nzTitle]="listStateOfQuestionaire[3]"
            [nzValueStyle]="{ color: 'green' }"></nz-statistic>
          <ng-template #prefixTplTwo><span nz-icon></span></ng-template>
        </nz-card>

        <nz-card>
          <nz-statistic
            [nzValue]="numberOfSend"
            [nzTitle]="listStateOfQuestionaire[1]"
            [nzValueStyle]="{ color: 'red'}"></nz-statistic>
          <ng-template #prefixTplTwo><span nz-icon></span></ng-template>
        </nz-card>
      </nz-col>

      <div class="funcitionality">
        <nz-col>
          <h5 nz-typography>Servizi</h5>
          <button nz-button [nzSize]="size"
            (click)="!isInEditing && !isClickedNewUser &&showModal('sendQuestionnaires')"
            [disabled]="isExpiredQuestionnaire()"
            nz-popover
            [nzPopoverTitle]="isExpiredQuestionnaire() ? 'La licenza è scaduta, non puoi inviare':''">
            <span nz-icon style="color: red;" nzType="send"></span>
            Invia Questionari
          </button>
          <button nz-button [nzSize]="size"
            [disabled]="!receivedObject?.attivo"
            nz-popover
            [nzPopoverTitle]="!receivedObject?.attivo ? 'Il tecnico ti deve abilitare' : ''"
            (click)="!isInEditing && !isClickedNewUser && showModal('elaboratorQuestionnaires')">
            <span nz-icon style="color: red;" nzType="fund-view"></span>
            Elabora Questionari
          </button>
        </nz-col>
      </div>
    </nz-row>
  </div>
</div>

<div class="structure">
  <nz-row class="table-manager">
    <div *ngIf="uniqueRoles.length>2" class="table-operations">
      <button *ngFor="let repart of uniqueRoles" nz-button
        (click)="filterByRepart(repart)">
        {{ repart }}
      </button>
    </div>
    <div class="manage-strucuture">
      <button id="addUserButton" nz-button [nzSize]="size"
        nz-popover
        nzPopoverTitle="Aggiungi Destinatario"
        nzPopoverContent="Assicurati di aver verificato tutte le modifiche prima di salvare."
        nzPopoverTrigger="hover"
        (click)="!isInEditing && !isClickedNewUser && addClient()">
        <span nz-icon style="color: red;" nzType="plus-circle"></span>
        Aggiungi Destinatario
      </button>
      <button nz-button [nzSize]="size"
        (click)="!isInEditing  && !isClickedNewUser && downloadExcel()"
        nz-popover
        nzPopoverTitle="Esporta Excel"
        nzPopoverContent="Permette di esportare la struttura dei Destinatari in un foglio Excel da compilare con i contatti dei Destinatari."
        nzPopoverTrigger="hover">
        <span nz-icon style="color: red;" nzType="export"></span>
        Esporta Excel
      </button>
      <button nz-button [nzSize]="size"
        (click)="!isInEditing && !isClickedNewUser  && handleImport()"
        nz-popover
        nzPopoverTitle="Importa Excel"
        nzPopoverContent="Consente di importare la struttura compilata con i contatti dei Destinatari dal file Excel esportato precedentemente."
        nzPopoverTrigger="hover">
        <span nz-icon style="color: red;" nzType="import"></span>
        Importa Excel
      </button>
      <input type="file" (change)="onFileSelected($event,listOfData)"
        style="display: none;" #fileInput>

    </div>
  </nz-row>

  <nz-table #filterTable [nzData]="listOfData" nzTableLayout="fixed"
    [nzScroll]="scroll"
    class="ant-table-wrapper"
    [nzLoading]="loading"
    [nzFrontPagination]="false"
    nzSize="middle"
    nzTableLayout="auto"
    [nzShowPagination]="false">
    <thead>
      <tr>
        <th>Nominativo</th>
        <th>Email/Cellulare</th>
        <th
          [nzFilters]="listRepartOfQuestionaireFilter"
          [nzFilterFn]="functionListRepartsOfQuestionaireFilter">Reparto</th>
        <th
          [nzFilters]="isClima? [] : listStateOfQuestionaireFilter"
          [nzFilterFn]="isClima? null :functionListStateOfQuestionaireFilter">Stato
          Questionario</th>
        <th>Azione</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of filterTable.data; let i = index">
        <td>
          <ng-container *ngIf="editingRow !== i; else editMode">
            {{ data.name }}
          </ng-container>
          <ng-template #editMode>
            <input nz-input
              type="text"
              [(ngModel)]="data.name"
              placeholder="Inserisci Nominativo"
              required />
          </ng-template>
        </td>
        <td>
          <ng-container *ngIf="editingRow !== i; else editModeAddress">
            {{ data.address }}
          </ng-container>
          <ng-template #editModeAddress>
            <input nz-input
              type="text"
              [(ngModel)]="data.address"
              placeholder="Inserisci Email/Cellulare"
              required
              [ngModelOptions]="{ updateOn: 'blur' }"
              #addressInput="ngModel"
              [pattern]="emailOrPhonePattern"
              [ngClass]="{ 'is-invalid': addressInput.invalid && addressInput.touched }" />
            <div *ngIf="addressInput.invalid && addressInput.touched"
              class="error-message">
              *Inserisci un'email o un numero di cellulare con il prefisso della
              nazione (es. '+393..')
            </div>
          </ng-template>
        </td>
        <td>
          <ng-container
            *ngIf="!(editingRow !== null && editingRow === i && isClickedNewUser); else editModeRole">
            {{ data.role }}
          </ng-container>
          <ng-template #editModeRole>
            <nz-select
              [(ngModel)]="data.role"
              nzAllowClear
              nzPlaceHolder="Seleziona un reparto"
              required>
              <ng-container *ngFor="let role of uniqueRoles">
                <ng-container *ngIf="role !== 'Tutti'">
                  <nz-option [nzValue]="role" [nzLabel]="role"></nz-option>
                </ng-container>
              </ng-container>
            </nz-select>
          </ng-template>
        </td>
        <td>
          <ng-container>
            {{ isClima && data.status !== listStateOfQuestionaire[0] ?
            nameAnonimo : data.status}} </ng-container>
          <div style="width: 150px;">
            <nz-progress nzSize="small"
              *ngIf='data.status!==listStateOfQuestionaire[0] && data.status!==listStateOfQuestionaire[1] && !isClima'
              [nzPercent]="(data.stateCompilation* 100).toFixed(0)"
              nzStrokeColor="var(--secondary-color)"></nz-progress>
          </div>
        </td>
        <td class="actions">
          <ng-container *ngIf="editingRow !== i; else saveCancelButtons">

            <nz-row>
              <span *ngIf='data.status!==listStateOfQuestionaire[3] || isClima'
                nz-icon
                nzType="edit" nzTheme="outline"
                (click)="!isInEditing && !isClickedNewUser  && toggleEditMode(i)"
                nz-popover
                nzPopoverTitle="Modifica Destinario"
                nzPopoverContent="Puoi modificare il nominativo, l'email e il cellulare del destinatario."></span>

              <button
                nzType="primary"
                *ngIf='data.status===listStateOfQuestionaire[0]'
                nz-icon nzType="delete" nz-popconfirm
                nz-popover
                nzPopoverTitle="Cancella Destinario"
                nzPopoverContent="Elimina definitivamente il Destinatario dal sistema."
                nzPopconfirmTitle="Sei sicuro di volere cancellare?"
                (nzOnConfirm)="!isInEditing && deleteRow(i)" nzTheme="outline">
              </button>
              <button
                nzType="default"
                *ngIf="data.status !== listStateOfQuestionaire[0]"
                nz-icon nzType="info-circle" nz-popover
                nzPopoverTitle="Informazione"
                nzPopoverContent="Non è possibile cancellare il Destinatario perché la licenza è stata già utilizzata.">
              </button>
            </nz-row>

          </ng-container>
          <ng-template #saveCancelButtons>
            <button nz-icon nzType="check-circle" [nzTheme]="'twotone'"
              [nzTwotoneColor]="'#52c41a'"
              nz-popover
              nzPopoverTitle="Salva modifiche Destinatario"
              nzPopoverContent="Assicurati di aver verificato tutte le modifiche prima di salvare."
              nzTheme="outline"
              (click)="confirmRow(i)"></button>
            <button nz-icon nzType="close-circle" [nzTheme]="'twotone'"
              [nzTwotoneColor]="'red'"
              nz-popover
              (click)="resetRow(i)"
              nzPopoverTitle="Cancella modifiche Destinatario"
              nzPopoverContent="Sei sicuro di voler cancellare tutte le modifiche? Questa azione non può essere annullata."></button>
          </ng-template>
        </td>
      </tr>
    </tbody>

  </nz-table>
</div>

<div class="space-footer"></div>

<nz-modal [(nzVisible)]="isVisibleSendQuestionnaires"
  nzTitle="Invia Questionari"
  (nzOnCancel)="handleCancel('sendQuestionnaires')"
  (nzOnOk)="handleOk('sendQuestionnaires')"
  [nzFooter]="null">
  <ng-container *nzModalContent>
    <div class="send-request">
      <span style="display: flex; align-items: center;">
        <span nz-icon nzType="info-circle"
          style="margin-right: 8px;"></span> {{this.textSend}}
      </span>

      <div *ngIf="showProgressSend">
        <nz-progress [nzPercent]="percentSend" nzType="circle"
          [nzWidth]="80"></nz-progress>
        <p> Inviati: {{ pecentSendString }}</p>
      </div>

      <button
        nz-button
        nzType="primary"
        [disabled]="isActiveSend()"
        [nzLoading]="showProgressSend"
        nz-popconfirm
        nzPopconfirmTitle="Sei sicuro di voler inviare il questionario?"
        (nzOnConfirm)="sendRequest(setOfCheckedId)">
        Invia
      </button>
    </div>
    <div *ngIf="uniqueRoles.length>2"
      class="table-operations-send-questionners">
      <button *ngFor="let repart of uniqueRoles"
        nz-button
        (click)="filterByRepartOfSend(repart)">
        {{ repart }}
      </button>
    </div>
    <nz-table
      #rowSendTable
      [nzScroll]="scroll"
      [nzFrontPagination]="false"
      [nzLoading]="loading"
      [nzShowPagination]="false"
      [nzData]="listOfDataToSend"
      (nzCurrentPageDataChange)="onCurrentPageDataChange($event)">
      <thead>
        <tr>
          <th
            id="selectAllCheck"
            [nzChecked]="checked"
            [nzIndeterminate]="indeterminate"
            nzLabel="Seleziona tutti"
            (nzCheckedChange)="onAllChecked($event)"> Seleziona Tutti</th>
          <th>Nominativo</th>
          <th>Email/Cellulare</th>
          <th
            [nzFilters]="isClima? [] : (hasListOfDataToSend ? listStateOfQuestionaireFilterToSend : listStateOfQuestionaireFilter)"
            [nzFilterFn]="isClima? null :functionListStateOfQuestionaireFilter"
            click="true">Filtra Stato</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of rowSendTable.data">
          <td
            [nzDisabled]="data.isComplete && !isClima"
            [nzChecked]="setOfCheckedId.has(data.address)"
            (nzCheckedChange)="onItemChecked(data.address, $event)"></td>
          <td>{{ data.name }}</td>
          <td>{{ data.address }}</td>
          <td>{{ isClima && data.status !== listStateOfQuestionaire[0] ?
            nameAnonimo : data.status}}</td>
        </tr>
      </tbody>
    </nz-table>
  </ng-container>
</nz-modal>

<nz-modal [(nzVisible)]="isVisibleElaboratorQuestionnaires"
  nzTitle="Elabora Questionari"
  (nzOnCancel)="handleCancel('elaboratorQuestionnaires')"
  [nzFooter]="null">
  <ng-container *nzModalContent>
    <div
      *ngIf="!isClima && !isTitolari  && completeUniqueRoles.length>2 "
      class="table-operations-send-questionners">
      <button *ngFor="let repart of completeUniqueRoles" nz-button
        (click)="filterByRepartOfSend(repart,true)">
        {{ repart }}
      </button>
    </div>
    <div class="table-container">
      <div *ngIf="!isClima && !isTitolari && completeQuestionnaires.length>1"
        class="table-wrapper">
        <nz-table #basicTable
          [nzData]="completeQuestionnaires"
          [nzScroll]="scroll"
          [nzFrontPagination]="false"
          [nzShowPagination]="false"
          *ngIf="completeQuestionnaires.length>1">
          <thead>
            <tr>
              <th>Nominativo</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of completeQuestionnaires"
              (click)="generateChart(data)">
              <td>
                <h5>{{ data.name }}</h5>
                <p>{{ data.address }}</p>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
      <div class="chart-container">
        <nz-select
          class="multiple-repart"
          *ngIf="isClima && uniqueRoles.length>2 " nzMode="multiple"
          nzPlaceHolder="Seleziona i reparti da analizzare"
          [(ngModel)]="listOfSelectedFilterClima"
          (ngModelChange)="rigenerateClima()">
          <nz-option
            *ngFor="let option of fileterClima"
            [nzLabel]="option"
            [nzValue]="option"
            [nzHide]="!isNotSelected(option)"></nz-option>
        </nz-select>

        <button nz-button [nzSize]="size" class="export-button"
          nz-tooltip nzTooltipTitle="Esporta Questionario"
          nzTooltipPlacement="left"
          (click)="exportChart(actualName)">
          <span nz-icon style="color: red;" nzType="download"></span>
        </button>

        <highcharts-chart [Highcharts]="Highcharts"
          [options]="chartOptions"
          class="chart-style"></highcharts-chart>
        <div class="comment-container">
          <nz-comment *ngIf="commentChart !== ''">
            <nz-comment-content>
              <p>{{commentChart}}</p>
            </nz-comment-content>
            <nz-comment-action>Tecnico Amajor</nz-comment-action>
          </nz-comment>
        </div>
      </div>
    </div>

  </ng-container>

</nz-modal>

<div class="waiter" *ngIf="loading">

  <div *ngIf="showProgressSend" class="progress-container">
    <nz-progress
      [nzPercent]="percentSend"
      nzType="circle"
      [nzWidth]="100">
    </nz-progress>
    <p>Inviati: {{ pecentSendString }}</p>
  </div>

  <span *ngIf="!showProgressSend"
    class="ant-spin-dot ant-spin-dot-spin ng-star-inserted">
    <i class="ant-spin-dot-data"></i><i class="ant-spin-dot-data"></i><i
      class="ant-spin-dot-data"></i><i
      class="ant-spin-dot-data"></i>
  </span>
</div>