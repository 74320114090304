<nz-layout style="height: 100%;">
  <nz-content>
    <router-outlet></router-outlet>
  </nz-content>
  <nz-footer class="uiFooter" *ngIf="g.globalFooter">
    <div nz-row>
      <div nz-col nzMd="16" nzSm="24" nzXs="24">v{{g.appVersion}} ©2024 Amajor
        Società Benefit S.P.A. <i class="fa fa-envelope"></i><a
          class="footerLink"
          href="mailto:info@amajorsb.com">info@amajorsb.com</a><i
          class="fa fa-phone"></i><a class="footerLink"
          href="tel:+390499700548">049 9700548</a>
        | <a
          href="https://www.iubenda.com/privacy-policy/69931008/cookie-policy"
          class="footerLink cky-banner-element">Cookie policy</a>
        | <a class="footerLink"
          href="https://www.amajorsb.com/wp-content/uploads/2023/07/Informativa-Online-Potenziale-Cliente-2F-O421246G-.pdf"
          target="_blank">Privacy policy</a></div>
      <div nz-col nzMd="8" nzSm="24" nzXs="24" class="footerRight">

      </div>
    </div>
  </nz-footer>
</nz-layout>
