import { CfValidatorDirective } from "./../../../services/validator.cf";
import { HttpParams } from "@angular/common/http";
import { Component, OnInit, ViewContainerRef } from "@angular/core";
import {
  AbstractControl,
  UntypedFormArray,
  FormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  MinLengthValidator,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { NzModalService } from "ng-zorro-antd/modal";
import { CompileService } from "src/app/services/compile.service";
import { FireService } from "src/app/services/fire.service";
import { NodeService } from "src/app/services/node.service";
import { StorageService } from "src/app/services/storage.service";
import { GlobalService } from "src/app/shared/global.service";

// ######################################
// FUNCTION: cFValidator
function cFValidator(control: AbstractControl): { [key: string]: boolean; } | null {

  let codiceFiscaleRegex: RegExp = /^[A-Z]{6}\d{2}[A-Z]{1}\d{2}[A-Z]{1}\d{3}[A-Z]{1}$/;

  if (control.value !== null && control.value.length !== 0) {
    if (!codiceFiscaleRegex.test(control.value.trim().toUpperCase())) {
      return { invalidCf: true };
    }
  }
  return null;
}

// ######################################
// FUNCTION: emailValidator
function emailValidator(control: AbstractControl): { [key: string]: boolean; } | null {

  let emailRegex: RegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  if (control.value !== null && control.value.length !== 0) {
    if (!emailRegex.test(control.value.trim().toUpperCase())) {
      return { invalidCf: true };
    }
  }
  return null;
}
// ######################################
// FUNCTION: pIvaValidator
function pIvaValidator(control: AbstractControl): { [key: string]: boolean; } | null {
  if (control.value !== null && control.value.length !== 0) {
    if (control.value.length > 0 && control.value.length < 11) {
      return { invalidPiva: true };
    } else if (!validatePiva(control.value)) {
      return { invalidPiva: true };
    }
  }
  return null;
}

// ######################################
// FUNCTION: validatePiva
function validatePiva(val): Boolean {
  if (!/^[0-9]{11}$/.test(val)) {
    return false;
  }
  return true;
}

// ######################################
// FUNCTION: validateCf
function validateCf(val): Boolean {
  var validi, i, s, set1, set2, setpari, setdisp;
  if (val == "") return false;
  val = val.toUpperCase();
  if (val.length != 16) return false;
  validi = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  for (i = 0; i < 16; i++) {
    if (validi.indexOf(val.charAt(i)) == -1) return false;
  }
  set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
  setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
  s = 0;
  for (i = 1; i <= 13; i += 2) s += setpari.indexOf(set2.charAt(set1.indexOf(val.charAt(i))));
  for (i = 0; i <= 14; i += 2) s += setdisp.indexOf(set2.charAt(set1.indexOf(val.charAt(i))));
  if (s % 26 != val.charCodeAt(15) - "A".charCodeAt(0)) return false;
  return true;
}

@Component({
  selector: "app-intestazione",
  templateUrl: "./intestazione.component.html",
  styleUrls: ["./intestazione.component.scss"],
})
export class IntestazioneComponent implements OnInit {
  head: any;
  userForm: UntypedFormGroup;
  loading: boolean = true;
  invalidForm: boolean = false;
  questTitle: string;
  questSubTitle: string;
  questVersion: string;
  logo: SafeResourceUrl;
  privacyCheck: any;
  privacy: any = { titolo: "(nessuna privacy selezionata)", allegato: {} };
  user: any;
  isSpinning: boolean = false;
  current = 0;

  // ######################################
  // FUNCTION: constructor
  constructor(
    private storage: StorageService,
    public router: Router,
    public g: GlobalService,
    public compile: CompileService,
    private fire: FireService,
    private node: NodeService,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService
  ) { }

  // ######################################
  // FUNCTION: ngOnInit
  ngOnInit(): void {
    this.user = this.storage.getActiveQuest();

    if (this.user) {
      this.fire
        .getSubDocOnce(
          "clienti",
          this.user.azienda,
          "licenze",
          this.user.licenza,
          "compilazioni",
          this.user.reparto + this.user.utente
        )
        .subscribe((comp) => {
          let q = comp.data();
          if (q && q.questionario) {
            // GET QUERY CODE
            this.fire.getDocOnce("clienti", this.user.azienda, "licenze", this.user.licenza).subscribe((res) => {
              if (res) {
                res = res.data();
                this.questVersion = res.questionario + res.versione;
              }
            });
            this.questTitle = q.titoloQ;
            this.questSubTitle = q.questionario.titolo ? q.questionario.titolo["ITA"] : null;
            this.head = q.questionario.anagrafica;

            if (!this.compile.custLogo) {
              this.compile.saveLogo(q.questionario.logo);
            }

            this.userForm = new UntypedFormGroup({});
            this.head.forEach((h, hIndex) => {
              let fc = new UntypedFormControl();

              const validatorsToAdd: ValidatorFn[] = [];
              if (h.tipo !== "Nota") {
                if (h.tipo === "Elenco") {
                  if (h.multiplo == null) h.multiplo = false;
                  h.opzioni = h.opzioni.replace(new RegExp(/\s/g), "");
                  h.values = [];
                  h.values = h.opzioni.split(",");
                  if (h.richiesto) {
                    validatorsToAdd.push(Validators.required);
                    validatorsToAdd.push(Validators.minLength(1));
                  }
                  if (h.multiplo) {
                    let multipleArray = new UntypedFormArray([], validatorsToAdd);
                    this.userForm.addControl(h.etichetta, multipleArray);
                  }
                } else if (h.tipo === "Range") {
                  validatorsToAdd.push(Validators.min(h.minimo));
                  validatorsToAdd.push(Validators.max(h.massimo));
                } else if (h.tipo === "Booleano") {
                  fc.setValue(false);
                  if (h.richiesto) {
                    validatorsToAdd.push(Validators.requiredTrue);
                  }
                } else if (h.tipo === "Codice fiscale") {
                  validatorsToAdd.push(cFValidator);
                } else if (h.tipo === "Partita iva") {
                  validatorsToAdd.push(pIvaValidator);
                } else if (h.tipo === "Email") {
                  validatorsToAdd.push(emailValidator);
                }
                if (h.richiesto && h.tipo !== "Booleano") {
                  validatorsToAdd.push(Validators.required);
                }
                if (!h.multiplo) {
                  fc.setValidators(validatorsToAdd);
                  this.userForm.addControl(h.etichetta, fc);
                }
              }
            });

            if (q.questionario.privacy) {
              this.fire.get("privacy", q.questionario.privacy).subscribe((privacy) => {
                if (privacy) {
                  this.privacy = privacy;
                  this.privacyCheck = privacy.checkbox["ITA"];
                  this.privacyCheck.forEach((c) => {
                    let fc = new UntypedFormControl();
                    const validatorsToAdd: ValidatorFn[] = [];
                    if (c.richiesto) {
                      validatorsToAdd.push(Validators.required);
                    }
                    fc.setValidators(validatorsToAdd);
                    this.userForm.addControl("_privacy" + c.codice, fc);
                  });
                  this.loading = false;
                }
              });
            } else {
              this.loading = false;
            }
          }
        });
    } else {
      console.error("localstorage (dati compilazione non trovati)");
      this.router.navigateByUrl("/compilazione/accesso-vietato");
    }
  }

  next(): void {
    this.current += 1;
  }

  // ######################################
  // FUNCTION: onSubmit
  onSubmit() {
    if (this.userForm.invalid) {
      this.invalidForm = true;
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: adds a smooth scrolling effect
    });
    } else {
      this.isSpinning = true;
      this.invalidForm = false;

      // console.log(this.userForm.value);
      // sanificate string "codice fiscale"
      if (this.userForm.value['Codice fiscale']) this.userForm.value['Codice fiscale'] = this.userForm.value['Codice fiscale'].trim().toUpperCase();

      this.fire
        .getSubDocOnce(
          "clienti",
          this.user.azienda,
          "licenze",
          this.user.licenza,
          "compilazioni",
          this.user.reparto + this.user.utente
        )
        .subscribe((comp) => {
          let q = comp.data();
          if (q) {
            q.compilazione.intestazione = this.userForm.value;
            this.fire
              .addSubCollection(
                "clienti",
                "licenze",
                "compilazioni",
                q,
                this.user.azienda,
                this.user.licenza,
                this.user.reparto + this.user.utente
              )
              .then((added) => {
                this.isSpinning = false;
                this.router.navigateByUrl("/compilazione/domande");
              })
              .catch((err) => {
                this.isSpinning = false;
                // console.log(err);
              });
          }
        });
    }
  }

  // ######################################
  // FUNCTION: downloadFile
  downloadFile(path) {
    let params = { "filepath": path };
    this.node.getData(params, "/download").subscribe(async (data) => {
      if (data.status !== 200) {
        data.filename = "privacy.pdf";
        var fileURL: string = URL.createObjectURL(data);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = fileURL;
        a.target = "_blank";
        a.click();
      }
    });
  }

  // ######################################
  // FUNCTION: onCheckboxChange
  onCheckboxChange(event: any, etichetta: string) {
    const selected = this.userForm.controls[etichetta] as UntypedFormArray;

    if (event.target.checked) {
      if (selected.push) {
        selected.push(new UntypedFormControl(event.target.value));
      } else {
        selected.push(new UntypedFormControl(event.target.value));
      }
    } else {
      if (selected.removeAt) {
        const index = selected.controls.findIndex((x) => x.value === event.target.value);
        selected.removeAt(index);
      } else {
        selected.clear();
      }
    }
  }
}
